import React, { useRef, useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import heroAnimation from '../../animations/redpoint-hero-animation.json'
import mobileHeroAnimation from '../../animations/redpoint-hero-animation-mobile.json'

import styles from './homepageHero.module.css'
import headerStyles from '../../components/header.module.css'
// eslint-disable-next-line import/no-unresolved
import { useWindowSize } from '../../hooks/useWindowSize.hook'
import linkStyle from '../../styles/components/links.module.css'

const HomepageHero = ({ title, companyBlockRef }) => {
  const { width } = useWindowSize()
  const heroRef = useRef(null)
  const observerTargetRef = useRef(null)
  const [isAnimating, setIsAnimating] = useState(false)
  let scrollY = 0
  let wasHidden = false
  let ts

  const disableScroll = () => {
    scrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
    document.body.style.width = '100%'
  }

  const enableScroll = () => {
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, scrollY)
  }

  const preventTouchMove = (e) => {
    if (!e.cancelable) return
    const te = e.changedTouches[0].clientY
    e.preventDefault()
    if (ts > te) {
      scrollDown()
      console.log('down')
    } else {
      console.log('up')
    }
  }

  const preventWheel = (e) => {
    e.preventDefault()
    if (e.deltaY > 0) {
      if (!wasHidden) {
        scrollDown()
      }
    }
  }

  useEffect(() => {
    const hero = heroRef.current
    const companyBlock = document.querySelector('.slideTop')
    const header = document.getElementsByTagName('header')[0]
    document.addEventListener('touchmove', preventTouchMove, { passive: false })
    document.addEventListener('wheel', preventWheel, { passive: false })

    document.addEventListener('touchstart', (e) => {
      ts = e.touches[0].clientY
    })

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting && !wasHidden && !isAnimating) {
          disableScroll()

          wasHidden = true
          setIsAnimating(true)
          hero.classList.add(styles.hidden)
          companyBlock.classList.add('slide')
          header.classList.remove(headerStyles.redHead)

          setTimeout(() => {
            enableScroll()
            document.removeEventListener('touchmove', preventTouchMove)
            document.removeEventListener('wheel', preventWheel)
            setIsAnimating(false)
          }, 1000)
        } else if (entry.isIntersecting && wasHidden && !isAnimating) {
          document.addEventListener('wheel', preventWheel, { passive: false })
          document.addEventListener('touchmove', preventTouchMove, { passive: false })
          wasHidden = false
          hero.classList.remove(styles.hidden)
          companyBlock.classList.remove('slide')
          header.classList.add(headerStyles.redHead)
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1
    })

    if (observerTargetRef.current) {
      observer.observe(observerTargetRef.current)
    }

    return () => {
      if (observerTargetRef.current) {
        observer.unobserve(observerTargetRef.current)
      }
      document.removeEventListener('touchmove', preventTouchMove)
      document.removeEventListener('wheel', preventWheel)
    }
  }, [width])

  const scrollDown = () => {
    window.scrollBy(0, 2)
  }

  return (
    <>
      <div
        ref={observerTargetRef} style={{
          height: '1px',
          padding: 0,
          position: 'absolute',
          top: 0,
          background: 'transparent'
        }}
      />
      <div className={styles.homepageHero} ref={heroRef}>
        <div className={styles.animationWrap}>
          <Lottie
            animationData={width > 768 ? heroAnimation : mobileHeroAnimation}
            loop
            autoplay
            onLoopComplete={scrollDown}
          />
        </div>
        <div className={styles.heroSkipBtn}>
          <button
            type='submit'
            className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.skipButton}`}
            onClick={scrollDown}
          >Skip
            <span>
              <div className={`${linkStyle.leftArrow} ${linkStyle.iconRed}`}>
                <svg width='24' height='27' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M11.9108 27L0.417969 14.1458L1.91188 12.7344L10.978 23.5847L10.7732 1.59982e-05L13.0483 -5.5209e-07L12.8587 23.5847L21.9247 12.7344L23.418 14.1458L11.9108 27Z'
                    fill='currentColor'
                  />
                </svg>
              </div>
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export default HomepageHero
